<template>
<div>
<v-container>
  <v-row>

    <!-- Begin left column -->
    <v-col md="4" sm="6" cols="12" class="pa-2">

    <!-- Begin Air Quality -->
    <v-card class="mx-auto mb-4">


      <v-card-title class="section-title grey lighten-2">Air Quality</v-card-title>
      <v-card-text class="pt-3">

        <div>&nbsp;</div>
        <div id="single-city-aqi"></div>

      </v-card-text>

      <v-card-actions class="section-bottom">
      </v-card-actions>

    </v-card>
    <!-- End Air Quality -->

    <!-- Begin Crypto -->
    <v-card class="mx-auto mb-4">

      <v-card-title class="section-title grey lighten-2">Cryptocurrencies</v-card-title>
      <v-card-text style="padding: 15px 15px">

      <coingecko-coin-list-widget  coin-ids="bitcoin,ethereum,0x,kyber-network" currency="usd" locale="en"></coingecko-coin-list-widget>


      </v-card-text>

      <v-card-actions class="section-bottom">
      </v-card-actions>

    </v-card>
    <!-- End Crypto -->


    <!-- Begin Ethereum Gas Price -->
    <v-card class="mx-auto mb-4">


      <v-card-title class="section-title grey lighten-2">Ethereum Gas Price</v-card-title>
      <v-card-text class="pt-3">

        <v-row v-show="EthereumGasPrice.safe">
          <v-col class="text-center">
            <strong>Low</strong><br/><span class='eth-gas-price'>{{ EthereumGasPrice.safe }}</span><br/>gwei<br />
          </v-col>
          <v-col class="text-center">
            <strong>Average</strong><br/><span class='eth-gas-price' style="color: #5ba2d7">{{ EthereumGasPrice.proposed }}</span><br/>gwei
          </v-col>
          <v-col class="text-center">
            <strong>Fast</strong><br/><span class='eth-gas-price'>{{ EthereumGasPrice.fast }}</span><br/>gwei
          </v-col>
        </v-row>

        <v-row class="mx-auto text-center">
          <v-col>
            <v-btn small depressed href="https://etherscan.io/gastracker" rel="nofolow" target="_blank">View</v-btn>
          </v-col>
          <v-col>
            <v-btn small depressed href="https://etherscan.io/chart/gasprice" rel="nofolow" target="_blank">Chart</v-btn>
          </v-col>
        </v-row>

      </v-card-text>

      <v-card-actions class="section-bottom">
      </v-card-actions>

    </v-card>
    <!-- End Ethereum Gas Price -->

    </v-col>
    <!-- End Left Column -->

    <!-- Begin Middle column -->
    <v-col md="4" sm="6" cols="12" class="pa-2">

    <!-- Begin World Clock -->
    <v-card class="mx-auto mb-4">

      <v-card-title class="section-title grey lighten-2">World Clock</v-card-title>
      <v-card-text style="padding: 10px 10px">

        <div style="text-align:center; margin: 0px 3px 0px 3px">

<!-- Beijing -->
<div class="cleanslate w24tz-current-time w24tz-small" style="display: inline-block !important; visibility: hidden !important; min-width:260px !important; min-height:80px !important;"><p><a href="//24timezones.com/Beijing/time" style="text-decoration: none" class="clock24" id="tz24-1591083581-c133-eyJob3VydHlwZSI6MTIsInNob3dkYXRlIjoiMSIsInNob3dzZWNvbmRzIjoiMCIsImNvbnRhaW5lcl9pZCI6ImNsb2NrX2Jsb2NrX2NiNWVkNjAyM2RjYTlkOCIsInR5cGUiOiJkYiIsImxhbmciOiJlbiJ9" title="Clock - Beijing" target="_blank" rel="nofollow">Beijing</a></p><div id="clock_block_cb5ed6023dca9d8"></div></div>

<!-- London -->
<div class="cleanslate w24tz-current-time w24tz-small" style="display: inline-block !important; visibility: hidden !important; min-width:260px !important; min-height:80px !important;"><p><a href="//24timezones.com/London/time" style="text-decoration: none" class="clock24" id="tz24-1591083795-c1136-eyJob3VydHlwZSI6MTIsInNob3dkYXRlIjoiMSIsInNob3dzZWNvbmRzIjoiMCIsImNvbnRhaW5lcl9pZCI6ImNsb2NrX2Jsb2NrX2NiNWVkNjAzMTMyOTcyNSIsInR5cGUiOiJkYiIsImxhbmciOiJlbiJ9" title="World Clock :: London" target="_blank" rel="nofollow">London</a></p><div id="clock_block_cb5ed6031329725"></div></div>

<!-- New York -->
<div class="cleanslate w24tz-current-time w24tz-small" style="display: inline-block !important; visibility: hidden !important; min-width:260px !important; min-height:80px !important;"><p><a href="//24timezones.com/New-York/time" style="text-decoration: none" class="clock24" id="tz24-1591083862-c1179-eyJob3VydHlwZSI6MTIsInNob3dkYXRlIjoiMSIsInNob3dzZWNvbmRzIjoiMCIsImNvbnRhaW5lcl9pZCI6ImNsb2NrX2Jsb2NrX2NiNWVkNjAzNTYzNzViMSIsInR5cGUiOiJkYiIsImxhbmciOiJlbiJ9" title="time in New York" target="_blank" rel="nofollow">New York</a></p><div id="clock_block_cb5ed60356375b1"></div></div>

<!-- Los Angeles -->
<div class="cleanslate w24tz-current-time w24tz-small" style="display: inline-block !important; visibility: hidden !important; min-width:260px !important; min-height:80px !important;"><p><a href="//24timezones.com/Los-Angeles/time" style="text-decoration: none" class="clock24" id="tz24-1591083894-c1137-eyJob3VydHlwZSI6MTIsInNob3dkYXRlIjoiMSIsInNob3dzZWNvbmRzIjoiMCIsImNvbnRhaW5lcl9pZCI6ImNsb2NrX2Jsb2NrX2NiNWVkNjAzNzY1NDk2ZCIsInR5cGUiOiJkYiIsImxhbmciOiJlbiJ9" title="Los Angeles actual time" target="_blank" rel="nofollow">Los Angeles</a></p><div id="clock_block_cb5ed603765496d"></div></div>

        </div>

      </v-card-text>



    </v-card>
    <!-- End World Clock -->

    <!-- Begin Market  -->
    <v-card class="mx-auto mb-4">

      <v-card-title class="section-title grey lighten-2">Market</v-card-title>
      <v-card-text style="padding: 0px 0px !important; text-align: center !important; overflow: hidden;">

        <iframe src="tradingview-market.html" seamless frameborder="0" height="700" border="0" style="padding-top: 0px 0px !important; text-align:center; background:white; margin-top: -10px; margin-left: -10px"></iframe>

      </v-card-text>

      <v-card-actions class="section-bottom">
      </v-card-actions>

    </v-card>
    <!-- End Forex  -->

    </v-col>
    <!-- End Middle Column -->

    <!-- Begin Right column -->
    <v-col md="4" sm="6" cols="12" class="pa-2">

    <!-- Begin Weather -->
    <v-card class="mx-auto mb-4">

      <v-card-title class="section-title grey lighten-2">Weather</v-card-title>
      <v-card-text style="padding: 15px 2px">

        <a href="https://www.accuweather.com/en/cn/Guangzhou/102255/current-weather/102255" class="aw-widget-legal">
        <!--
        Example Link: http://www.accuweather.com/en/cn/beijing/101924/weather-forecast/101924
        Hong Kong: 1123655
        Beijing: 57456
        Shanghai: 106577
        Guangzhou: 102255
        Hangzhou: 106832
        New York: 349727
        Tokyo: 226396
        Shenzhen: 58194
        Nanshan, Shenzhen: 2332635
        Singapore: 300597
        Taipei City: 315078
        Amsterdam: 249758
        -->
        </a><div id="awtd1443855091657" class="aw-widget-36hour"  data-locationkey="102255" data-unit="c" data-language="en-us" data-useip="false" data-uid="awtd1443855091657" data-editlocation="true"></div>

      </v-card-text>

      <v-card-actions class="section-bottom">
      </v-card-actions>

    </v-card>
    <!-- End Weather -->

    </v-col>
    <!-- End Right Column -->

  </v-row>
</v-container>
</div>
</template>

<script>

export default {
  data () {
    return {
      AqiCities: [
       "beijing",
       "shanghai",
       "suzhou",
       "hangzhou",
       // "guangzhou",
       "guangdong/guangzhou/tiyuxi",
       "shenzhen",
       "hongkong"
      ],
      EthereumGasPrice: {
        fast: null,
        proposed: null,
        safe: null
      }
    }
  },
  components: {
  },
  methods: {
    init () {
      this.loadAQI()
      this.loadEthereumGasPrice()
    },

    async loadAQI () {

/*eslint-disable */
      try {
        (function(w, d, t, f) {
            w[f] = w[f] || function(c, k, n) {
                var s = w[f], k = s['k'] = (s['k'] || (k ? ('&k=' + k) : ''));
                s['c'] =
                    c = (c instanceof Array) ? c : [c];
                s['n'] = n = n || 0;
                var L = d.createElement(t), e = d.getElementsByTagName(t)[0];
                L.async = 1;
                L.src = '//feed.aqicn.org/feed/' + (c[n].city) + '/' + (c[n].lang || '') + '/feed.v1.js?n=' + n + k;
                e.parentNode.insertBefore(L, e);
            };
        })(window, document, 'script', '_aqiFeed');

        var aqiWidgetConfig  =  [];
        // var counter = 0;

        var displayCity = function (aqi) {
          // counter++;
          // document.querySelector(".aqi-loading").hide()
          var e = document.createElement('div')
          e.innerHTML = aqi.text("<div class='aqi-section'><div class='aqi-section-left'><div class='aqi-city-name'>%cityname</div><div class='aqi-impact'>%impact</div></div><div class='aqi-section-right'><div class='aqi-number'><span style='%style;padding: 5px 10px'>%aqi</span></div></div></div>")
          document.getElementById("single-city-aqi").appendChild(e)

        }

        this.AqiCities.forEach(function(city){
          aqiWidgetConfig.push({
            city: city,
            lang:'cn',
            callback: displayCity
          });
        });

        window._aqiFeed(aqiWidgetConfig);

      } catch (err) {
        console.log(err)
      }

/*eslint-enable */

    },

    async loadEthereumGasPrice() {
      const url = 'https://api.etherscan.io/api?module=gastracker&action=gasoracle&apikey=FGWDZHHKWTF57ZJ4I8G6I2993JV9YVRQKX';
      try {
        const res = await window.axios.get(url);
        // console.log(res);
        this.EthereumGasPrice.fast = res.data.result.FastGasPrice;
        this.EthereumGasPrice.proposed = res.data.result.ProposeGasPrice;
        this.EthereumGasPrice.safe = res.data.result.SafeGasPrice;
        // console.log(this.pages)
      } catch (err) {
        // console.log(err);
        throw new Error(err);
      }
    },

    async addExternalScript (url) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.async = true;
      // script.defer = true;
      // script.setAttribute('src', url);
      // script.setAttribute('rel', "preload");
      document.getElementsByTagName('head')[0].appendChild(script);
    },

  },
  created () {
    this.init()
  },
  beforeMount () {
    this.addExternalScript("https://oap.accuweather.com/launch.js")
    this.addExternalScript("https://w.24timezones.com/l.js")
    // this.addExternalScript("/js/accuweather-launch.js")
    //this.addExternalScript("https://files.coinmarketcap.com/static/widget/currency.js")
    this.addExternalScript("https://widgets.coingecko.com/coingecko-coin-list-widget.js")
  }
 };
</script>

<style>
.section-title {
  font-size: 18px !important;
  font-weight: bold !important;
  padding: 5px 15px !important;
}
.section-bottom {
  padding: 0px !important;
  margin-top: -5px !important;
}
#single-city-aqi, #mutiple-city-aqi {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 8px;
}
.aqi-section {
  background-color: #f3f3f3;
  margin-bottom: 2px;
  display: flex;
  /*border-bottom: 1px solid #FFF;*/
}
.aqi-section-left, .aqi-section-right {
  padding: 0px;
  flex: 50%;
}
.aqi-city-name {
  margin-top: 8px;
  font-size: 21px;
  font-weight: bold;
  color: #444;
  text-align: center;
  font-family: Roboto, arial, "Heiti SC", "Microsoft Yahei", simsun, sans-serif !important;
}
.aqi-number {
  font-size: 22px;
  padding: 10px;
  margin: 5px 0px;
  text-align: left;
}
.aqi-impact {
  text-align: center;
  color: #888;
  margin-top: 0px;
  font-size: 12px;
  font-family: Roboto, arial, "Heiti SC", "Microsoft Yahei", simsun, sans-serif !important;
}
.aqi-time {
  text-align: center;
  font-size: 16px;
  color: #888;
}

.eth-gas-price {
  font-size: 20px;
  font-weight: bold;
  padding: 4px 8px;
  color: #b2b2b2;
}

.lt-632 div.aw-widget-36hour-inner div.aw-more-block.aw-more-block-first,
div.aw-widget-36hour-inner div.aw-more-block,
div.aw-widget-36hour-inner div.aw-fc-status {
  display: none !important;
}

.lt-632 div.aw-widget-36hour-inner div.aw-more-block.aw-more-block-first {
    display: none !important;
}
div.aw-widget-36hour-inner {
  min-width: 280px !important;
}



.w24tz-current-time {
  padding-top: 10px !important;
}

.w24tz-time {
  font-size: 20px !important;
}

</style>

